<template>
  <div class="px-4 py-2 stop">
    <div class="py-4 font-bold text-black border-b border-gray-300 title">
      紧急停止
    </div>
    <div class="py-6 content_item">
      <div class="flex items-center justify-between">
        <div class="flex items-center item">
          <div>机器编号：</div>
          <div class="py-2 pr-4 input_box">
            <a-input size="small" placeholder="请输入" />
          </div>
          <a-button size="small" type="primary"> 查询 </a-button>
        </div>
        <div class="flex items-center">
          <a-button size="small" type="danger">全部停止</a-button>
        </div>
      </div>
      <div class="pt-4">
        <a-table
          :data-source="dataSource"
          :columns="columns"
          :row-selection="rowSelection"
          :pagination="pagination"
        >
          <template slot="statusName" slot-scope="text, record">
            <div class="flex items-center" @click="details(record)">
              <span
                class="mr-2 circle"
                :class="text == '被停止' ? 'stop_span' : ''"
              ></span>
              <span>{{ text }}</span>
            </div>
          </template>
          <template slot="operation" slot-scope="text, record">
            <span class="cursor-pointer" @click="opration(record)">
              <a-tag :color="text == '立即停止' ? 'red' : 'green'">
                {{ text }}
              </a-tag>
            </span>
          </template>
        </a-table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  robotList,
  operatoinRobot,
  workRobot,
  disableRobot,
} from "@/api/robot";
const rowSelection = {
  onChange: (selectedRowKeys, selectedRows) => {
    console.log(
      `selectedRowKeys: ${selectedRowKeys}`,
      "selectedRows: ",
      selectedRows
    );
  },
  onSelect: (record, selected, selectedRows) => {
    console.log(record, selected, selectedRows);
  },
  onSelectAll: (selected, selectedRows, changeRows) => {
    console.log(selected, selectedRows, changeRows);
  },
};
export default {
  data() {
    return {
      show: true,
      value: null,
      value2: null,
      editingKey: "",
      checked: null,
      page: 1,
      limit: 10,
      pagination: {
        // 关于页码的配置属性
        total: 0,
        pageSize: 10,
        pageNo: 1,
        showSizeChanger:false, // 显示可改变每页数量
        pageSizeOptions: ["10", "20", "50", "100"], // 每页数量选项
        showTotal: (total) => `共 ${total} 条`, // 显示总数
        onShowSizeChange: (current, pageSize) =>
          this.onSizeChange(current, pageSize), // 改变每页数量时更新显示
        onChange: (page, pageSize) => this.onPageChange(page, pageSize), //点击页码事件
      },
      dataSource: [
        // {
        //   key: "0",
        //   number: "TradeCode 0",
        //   status: "运行中",
        //   operation: "立即停止",
        // },
        // {
        //   key: "1",
        //   number: "TradeCode 1",
        //   status: "被停止",
        //   operation: "取消操作",
        // },
        // {
        //   key: "2",
        //   number: "TradeCode 2",
        //   status: "被停止",
        //   operation: "取消操作",
        // },
        // {
        //   key: "3",
        //   number: "TradeCode 3",
        //   status: "运行中",
        //   operation: "立即停止",
        // },
        // {
        //   key: "4",
        //   number: "TradeCode 4",
        //   status: "被停止",
        //   operation: "取消操作",
        // },
      ],
      count: 2,
      cacheData: null,
      columns: [
        {
          title: "机器编号",
          dataIndex: "code",
        },
        {
          title: "机器人名称",
          dataIndex: "name",
          width: 120,
        },
        {
          title: "状态",
          dataIndex: "statusName",
          scopedSlots: { customRender: "statusName" },
          width: "100px",
          filters: [
            {
              text: "运行中",
              value: "运行中",
            },
            {
              text: "被停止",
              value: "被停止",
            },
          ],
          // filterDropdownVisible:true,
          onFilter: (value, record) => record.statusName.indexOf(value) === 0,
        },
        {
          title: "操作",
          dataIndex: "operation",
          scopedSlots: { customRender: "operation" },
        },
      ],
      rowSelection,
    };
  },
  created() {
    this.aquireRobotList();

    this.cacheData = this.dataSource.map((item) => ({ ...item }));
  },
  watch: {
    checked: function(newVal, oldVal) {
      this.rowSelection.onSelectAll();
    },
  },
  methods: {
    details(e) {
      console.log(e);
    },
    // 分页里size改变触发的函数
    onSizeChange(_, pageSize) {
      this.pagination.pageNo = 1;
      this.pagination.pageSize = pageSize;
      this.aquireRobotList();
    },
    // 页面改变时
    onPageChange(page, pageSize) {
      console.log(page, pageSize);
      this.pagination.pageNo = page;
      this.aquireRobotList();
    },
    onChange(e) {
      console.log(`checked = ${e.target.checked}`);
      this.checked = e.target.checked;
    },
    handleChange(value, key, column) {
      const newData = [...this.dataSource];
      const target = newData.filter((item) => key === item.key)[0];
      if (target) {
        target[column] = value;
        this.data = newData;
      }
    },
    opration(record) {
      console.log(record);
      if (record.statusName == "运行中") {
        this.$confirm({
          title: "操作提示",
          okText: "是",
          cancelText: "否",
          content:
            "是否确定停止所有机器人的运行，停止后机器人将不再运作，确定请按“是”，不确定可返回再看看。",
          onOk: () => {
            operatoinRobot({
              ids: [record.id],
              status: 0,
            })
              .then((res) => {
                console.log(res, res.msg == "操作成功");

                res.msg == "操作成功" ? this.aquireRobotList() : "";
              })
              .catch((error) => {
                console.log(error);
              });
            // return new Promise((resolve, reject) => {
            //   setTimeout(Math.random() > 0.5 ? resolve : reject, 1000);
            // }).catch(() => console.log("Oops errors!"));
          },
          onCancel() {},
        });
      } else {
        operatoinRobot({
          ids: [record.id],
          status: 1,
        })
          .then((res) => {
            res.msg == "操作成功" ? this.aquireRobotList() : "";
            console.log(res);
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    // 获取机器人列表
    aquireRobotList() {
      robotList({
        page: this.pagination.pageNo,
        limit: this.pagination.pageSize,
      })
        .then((res) => {
          console.log(res);
          let result = res.data;
          this.pagination.total = result.count;
          result.list.map((item, index) => {
            item.key = index;
            item.status == 1
              ? (item.statusName = "运行中")
              : (item.statusName = "被停止");
            item.status == 1
              ? (item.operation = "立即停止")
              : (item.operation = "取消操作");
          });
          this.dataSource = [];
          this.dataSource.push(...result.list);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 20px;
}
.input_box {
  width: 250px;
}
.client_input_box {
  width: 180px;
}
.shouqi {
  user-select: none;
}
.list_title {
  font-size: 16px;
}
.select_info {
  background: #afe8bc;
  .etc-gantanhao-copy-copy {
    font-size: 18px;
  }
  span {
    font-size: 14px;
  }
  i {
    font-style: normal;
    padding: 0px 2px;
    color: @primary-color;
  }
  .delete {
    color: red;
    user-select: none;
  }
}
.content_item {
  .item {
    .item_title {
      font-size: 16px;
    }
    span {
      font-size: 14px;
    }
    .list_title {
      color: #000000;
    }
    .list_msg {
      color: #7c7c7c;
    }
    div {
      font-size: 14px;
      color: #000000;
    }
    .item_box {
      div {
        width: 25%;
      }
    }
    span {
      font-size: 14px;
    }
  }
}
</style>
<style lang="less">
.stop {
  .ant-table-filter-dropdown-link {
    color: #000000 !important;
  }
  .circle {
    display: inline-block;
    height: 6px;
    width: 6px;
    background: #294DEA;
    border-radius: 50%;
  }
  .stop_span {
    background: red !important;
  }

  .editable-cell {
    position: relative;
  }

  .editable-cell-input-wrapper,
  .editable-cell-text-wrapper {
    padding-right: 24px;
  }

  .editable-cell-text-wrapper {
    padding: 5px 24px 5px 5px;
  }

  .editable-cell-icon,
  .editable-cell-icon-check {
    position: absolute;
    right: 0;
    width: 20px;
    cursor: pointer;
  }

  .editable-cell-icon {
    line-height: 18px;
    display: none;
  }

  .editable-cell-icon-check {
    line-height: 28px;
  }

  .editable-cell:hover .editable-cell-icon {
    display: inline-block;
  }

  .editable-cell-icon:hover,
  .editable-cell-icon-check:hover {
    color: #108ee9;
  }

  .editable-add-btn {
    margin-bottom: 8px;
  }
  tr td:last-child {
    display: flex;
    justify-content: flex-end;
    a:nth-child(1) {
      color: @primary-color;
    }
    a:nth-child(2) {
      color: red;
    }
  }
  tr td:nth-child(3) {
    div {
      display: flex;
      justify-content: flex-end;
    }
  }
  th:last-child {
    text-align: right;
  }
  th:nth-child(3) {
    text-align: right;
  }
  .ant-btn {
    display: flex;
    align-items: center;
  }
}
.cursor-pointer {
  cursor: pointer;
}
</style>
