import request from '@/utils/request'

const robotApi = {
    addRobot: '/admin/AdminTool/add', // 添加机器人
    robotList: '/admin/AdminTool/list', // 机器人列表
    deleteRobot: '/admin/AdminTool/delete', // 删除机器人
    operatoinRobot: '/admin/AdminTool/operate', // 禁止操作
    workRobot: '/admin/AdminTool/workList', // 预约时间点列表
    disableRobot: '/admin/AdminTool/disable', // 是非vip预留
    changeRobot:'/admin/AdminTool/edit',
    ruleRobot:'/admin/RobotType/list',//robot规则

    addWorkTime:'/admin/AdminTool/addWorkTime'
   
}
/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 * }
 * @param parameter
 * @returns {*}
 */
 export function ruleRobot(parameter) {
    return request({
      //  url: "http://localhost:8080/area/hot",
        //url:robotApi.ruleRobot,
        url:"/admin/RobotType/list",
        method: 'get',
        data: parameter
    })
}

 export function addRobot(parameter) {
    return request({
        url: robotApi.addRobot,
        method: 'post',
        data: parameter
    })
}

export function addWorkTime(parameter) {
    return request({
        url: robotApi.addWorkTime,
        method: 'post',
        data: parameter
    })
}
export function changeRobot(parameter) {
    return request({
        url: robotApi.changeRobot,
        method: 'get',
        params: parameter
    })
}
export function changeRobotUp(parameter) {
    return request({
        url: robotApi.changeRobot,
        method: 'post',
        data: parameter
    })
}
 export function robotList(parameter) {
    return request({
        url: robotApi.robotList,
        method: 'get',
        params: parameter
    })
}
export function deleteRobot(parameter) {
    return request({
        url: robotApi.deleteRobot,
        method: 'post',
        data: parameter
    })
}
export function operatoinRobot(parameter) {
    return request({
        url: robotApi.operatoinRobot,
        method: 'post',
        data: parameter
    })
}
export function workRobot(parameter) {
    return request({
        url: robotApi.workRobot,
        method: 'get',
        params: parameter
    })
}
export function disableRobot(parameter) {
    return request({
        url: robotApi.disableRobot,
        method: 'post',
        data: parameter
    })
}